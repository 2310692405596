import React from 'react';
import Page from '../../containers/Page';

import { ContentHeader } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { Block } from '../../components/Section';

import { initStateContent } from './content';

class ContentPage extends React.Component {
  state = { ...initStateContent(this.props) };

  render() {
    const defaultPage = 'over-ons';
    const page = this.props.match.params.page;
    const content = this.state[page] || this.state[defaultPage];
    return (
      <Page id="content-page">
        <ContentHeader {...this.props} page={page} content={content} />
        <Block page={page} classes={'section--background--skewed'} {...content}>
          {content.content}
        </Block>
        {content.customBlocks &&
          content.customBlocks.map((Block, i) => {
            return Block(i);
          })}
        <Footer />
      </Page>
    );
  }
}

export default ContentPage;
